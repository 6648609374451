import React from 'react'
import { Link } from 'react-router-dom'

import Drawer from '@material-ui/core/Drawer'
import Divider from '@material-ui/core/Divider'

import Logo from './Logo'
import NavLink from './NavLink'
import Sandwich from './Sandwich'
import './Nav.css'

const AccessibleLink = ({ alt, children, ...props }) => (
  <Link alt={alt} aria-label={alt} {...props}>
    {children}
  </Link>
)

export default class Nav extends React.Component {
  state = {
    open: false
  }

  handleOpen = () => this.setState({ open: true })

  handleClose = () => this.setState({ open: false })

  render () {
    const { open } = this.state

    return (
      <nav className='Nav'>
        <div className='Nav--Container container'>
          <Sandwich handleOpen={this.handleOpen} />
          <AccessibleLink alt='Link to Home' className='Link' to='/'>
            <Logo />
          </AccessibleLink>
          <div className='Nav--Desktop'>
            <NavLink to='/' exact>
              Home
            </NavLink>
            <NavLink to='/About/' exact>
              About
            </NavLink>
            <NavLink to='/Team/' exact>
              Our Team
            </NavLink>
            <NavLink to='/Blog/' exact>
              Blog
            </NavLink>
            <NavLink to='/contact/' exact>
              Contact
            </NavLink>
          </div>
        </div>
        <Drawer
          className='Nav--Drawer'
          variant='temporary'
          anchor='left'
          open={open}
          transitionDuration={{ enter: 100, exit: 100 }}
          onBlur={this.handleClose}
        >
          <AccessibleLink
            alt='Link to Home'
            className='Link'
            to='/'
            onClick={this.handleClose}
          >
            <Logo />
          </AccessibleLink>
          <Divider />
          <NavLink
            exact
            onClick={this.handleClose}
            to='/'
          >
            Home
          </NavLink>
          <NavLink
            exact
            onClick={this.handleClose}
            to='/About/'
          >
            About
          </NavLink>
          <NavLink
            exact
            onClick={this.handleClose}
            to='/Team/'
          >
            Our Team
          </NavLink>
          <NavLink
            exact
            onClick={this.handleClose}
            to='/Blog/'
          >
            Blog
          </NavLink>
          <NavLink
            exact
            onClick={this.handleClose}
            to='/contact/'
          >
            Contact
          </NavLink>
          <Divider />
        </Drawer>
      </nav>
    )
  }
}

