import React from 'react'
import LazyImage from '../components/LazyImage'
import Content from '../components/Content'
import PageHeader from '../components/PageHeader'
import './Home.css'

export default ({ fields }) => {
  const { title, subtitle, featuredImage, section, } = fields
  return (
    <main className='Home'>
      <PageHeader
        large
        title={title}
        subtitle={subtitle}
        backgroundImage={featuredImage}
      />

<div className='section'>
        <div className='container'>
          <Content source={section} />
          
          <h1>What We Do: </h1>

          <div class="row">
    <div class="imgbox"><div class="img"><div class="text"> <h3>Legal Practitioners </h3></div></div></div>
    <div class="imgbox"><div class="img"><div class="text"><h3>Notaries Public</h3></div></div></div>
    <div class="imgbox"><div class="img"><div class="text"> <h3>Conveyances</h3></div></div></div>
    <div class="imgbox"><div class="img"><div class="text"> <h3>Corporate Law </h3></div></div></div>
    <div class="imgbox"><div class="img"><div class="text"><h3>Notaries Public</h3></div></div></div>
    <div class="imgbox"><div class="img"><div class="text">  <h3>Labour Consultants</h3></div></div></div>
    <div class="imgbox"><div class="img"><div class="text"> <h3>Criminal and Commercial Law</h3></div></div></div>
    <div class="imgbox"><div class="img"><div class="text"><h3>Immigration and International Law</h3></div></div></div>
    <div class="imgbox"><div class="img"><div class="text">  <h3>Intellectual Property</h3></div></div></div>
    <div class="imgbox"><div class="img"><div class="text"><h3>Family Law</h3></div></div></div>
    <div class="imgbox"><div class="img"><div class="text">  <h3>Divorce Lawyers</h3></div></div></div>
   
        
      </div>
         
        </div>
       
      </div>
    </main>
  )
}
