import React from 'react'

import './Footer.css'


export default ({ globalSettings, socialSettings, navLinks }) => (
  <footer className='Footer'>
    <div className='container taCenter'>

      <span> © Copyright {new Date().getFullYear()} Ngongoni, Tawodzera, Pavari and Partners All rights reserved.</span>
    </div>
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"/>
<div class="icon-bar">
  <a href="#" class="whatsapp"><i class="fa fa-whatsapp"></i></a>

<a href="https://api.whatsapp.com/send?phone=263773272090&text=" class="float">
<i class="fa fa-whatsapp my-float"></i>
</a>
      </div>

  </footer>
)
