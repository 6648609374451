import React from 'react'

import PageHeader from '../components/PageHeader'
import Content from '../components/Content.js'
import './About.css'

export default ({ fields }) => {
  const { title, subtitle, featuredImage, section } = fields
  return (
    <div className='About'>
      <PageHeader
        title={title}
        subtitle={subtitle}
        backgroundImage={featuredImage}
      />
  
      <div className='section'>
        <div className='container'>
          <Content source={section} />
          <h2>Our Vision</h2>
          <p>To become a world class legal services provider</p>
         
          <h2>Our Mission</h2>
          <p>To promptly, efficiently and professionally render wide ranged legal services and representation in Zimbabwe, regionally and internationally:to the best of our ability within the confines of the law.</p>

          <h2>Our Core Values</h2>
          <p>Compliance and Accountability</p>
          <p>Honesty and Transparency</p>
          <p>Efficiency and Diligence</p>
          <p>Confidentiality</p>
          <p>Professionalism </p>
         
        </div>
      </div>
    </div>
  )
}
