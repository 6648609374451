import React from 'react'


import PageHeader from '../components/PageHeader'
import Content from '../components/Content'
import LazyImage from '../components/LazyImage'
import './Benefits.css'


import './Team.css'

export default ({ fields }) => {
  const { title, subtitle, featuredImage, benefits  } = fields
  return (
    <main className='Team'>
      <PageHeader
        title={title}
        subtitle={subtitle}
        backgroundImage={featuredImage}
      />
     <div className='section'>
        <div className='container'>
          <h1>Our Team</h1>
          {benefits.map(({ image, body }, key) => (
            <div
              className='Benefit'
              key={`benefit-${key}`}
            >
              <LazyImage src={image} alt={`benifit-${key}`} />
              <Content source={body} />
            </div>
          ))}
        </div>
      </div>
    </main>
  )
}
